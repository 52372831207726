<template>
  <div>
    <b-spinner v-if="loadingPage"></b-spinner>
    <div v-else class="container mt-4">
      <b-col>
        <b-row>
          <b-col class="d-flex align-items-center">
            <BackButton></BackButton>
            <h5 class="ml-2 mb-0">
              {{ translations.import_transactions_details.back_list }}
            </h5>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col>
            <b-card class="border-0" :header="translations.import_transactions_details.page_title" body-class="px-0"
                    header-class="border-0">
              <div class="col-12">
                <div class="row mb-5">
                  <div class="col-3">
                    <b>{{ translations.import_transactions_details.import_id }}</b>
                    <p>{{ details.id }}</p>
                  </div>
                  <div class="col-3">
                    <b>{{ translations.import_transactions_details.created_at }}</b>
                    <p>{{ formatDatetime(details.created_at) }}</p>
                  </div>
                  <div class="col-3">
                    <b>{{ translations.import_transactions_details.file_name }}</b>
                    <p>{{ details.original_filename }}</p>
                  </div>
                  <div class="col-3">
                    <b>{{ translations.import_transactions_details.created_by }}</b>
                    <p>{{ details.created_by }}</p>
                  </div>
                </div>
                <div class="row mb-5">
                  <div class="col-3">
                    <b>{{ translations.import_transactions_details.status }}</b>
                    <p class="text-capitalize">
                      <b-badge :variant="formatStatusLabel(details.status)">
                        <h6 class="badge-status mb-0">{{ details.status }}</h6>
                      </b-badge>
                    </p>
                  </div>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col class="d-flex justify-content-center align-items-start">
        <b-card
          class="border-0 mb-2 mt-3 flex-fill"
          body-class="px-0"
          header-class="border-0">
          <template #header>
            <div class="d-flex align-items-center justify-content-between">
              {{ translations.global.transactions }}
              <b-button v-if="isCompletedStatus(details.status)" variant="secondary" size="sm" @click="onExportClick">
                <feather type="download"></feather>
                {{ translations.global.export_csv }}
              </b-button>
            </div>
          </template>
          <financial-list
            v-model="currentPage"
            :header-data="TABLE_HEADERS"
            :data="transactions"
            :disabled="loadingPage"
            :items-per-page="itemsPerPage"
            @page-changed="loadTransactionsList"
            :total="total"
            :no-items-message="translations.import_transactions_details.transaction_list.empty_list"/>
        </b-card>
      </b-col>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import BackButton from '@/components/BackButton.vue';
import service from '@/services/finance-service';
import { parseResponseError } from '@/http/parsers/parse_response';
import FinancialList, { associateHeaderDataItem, generateCustomComponentColumn } from '@/components/Financial/FinancialList.vue';
import translations from '@/translations';
import utils from '@/scripts/tools/utils';
import { formatDatetime } from '@/helpers/finance';

export default {
  name: 'Details',
  components: {
    BackButton,
    FinancialList,
  },
  created() {
    const t = this.translations.import_transactions_details.transaction_list.fields;
    this.TABLE_HEADERS = [
      associateHeaderDataItem(t.transaction_id, 'id'),
      associateHeaderDataItem(t.client_name, 'client_name'),
      associateHeaderDataItem(t.program_id, 'patient_id'),
      associateHeaderDataItem(t.patient_name, 'patient_name'),
      associateHeaderDataItem(t.therapy, 'therapy_name'),
      associateHeaderDataItem(t.transaction_type, 'transaction_type'),
      associateHeaderDataItem(t.transaction_type_reason, 'transaction_type_reason'),
      associateHeaderDataItem(t.value, 'transaction_value'),
      associateHeaderDataItem(t.entry_type, 'entry_type'),
      associateHeaderDataItem(t.event_date, 'event_date'),
      associateHeaderDataItem(t.billable_date, 'billable_date'),
      associateHeaderDataItem(t.original_transaction_id, 'original_transaction_id'),
      associateHeaderDataItem(t.status, 'formatted_status'),
    ];
  },
  data() {
    return {
      translations: translations.finance,
      importId: this.$route?.params?.importID ? Number(this.$route.params.importID) : 0,
      details: {},
      transactions: [],
      loadingPage: true,
      itemsPerPage: 10,
      currentPage: 1,
      total: 0,
    };
  },
  async beforeMount() {
    await this.loadTransactionsList();
  },
  methods: {
    formatDatetime,
    async changePage(page) {
      this.currentPage = page;
      await this.loadTransactionsList();
    },
    async loadTransactionsList() {
      this.loadingPage = true;
      try {
        const offset = (this.currentPage - 1) * this.itemsPerPage;
        const limit = this.itemsPerPage;

        const { data } = await service.getManualTransactionDetails(this.importId, limit, offset);
        this.details = data;
        this.total = this.details.transactions.total;
        this.transactions = this.details.transactions.data;
        this.transactions.forEach(transaction => {
          const propsData = {
            variant: this.formatStatusLabel(transaction.status),
          };

          transaction.formatted_status = generateCustomComponentColumn('BBadge', propsData, transaction.status);
          transaction.event_date = formatDatetime(transaction.event_date);
          transaction.billable_date = formatDatetime(transaction.billable_date);
        });
      } catch (err) {
        this.$noty.error(`${this.translations.import_transactions_details.results.error_loading_list} ${parseResponseError(err)}`);
      } finally {
        this.loadingPage = false;
      }
    },
    formatStatusLabel(label) {
      if (this.isCompletedStatus(label)) return 'success';
      if (this.isFailedStatus(label)) return 'danger';
      return 'secondary';
    },
    isCompletedStatus(status) {
      if (!status) return false;
      return status.toLowerCase() === 'completed';
    },
    isFailedStatus(status) {
      if (!status) return false;
      return status.toLowerCase() === 'failed';
    },
    async onExportClick() {
      if (this.importId) {
        try {
          const buf = await service.exportManualTransactionsUpload(this.importId);
          const fileName = `export_${this.importId}_${moment().utc().format('YYYYMMDD_HHmmss')}.csv`;
          utils.downloadFile(buf, fileName);
        } catch (error) {
          this.$noty.error(
            `${this.translations.import_transactions_details.error_exporting}: ${parseResponseError(error)}`,
          );
        }
      }
    },
  },
};
</script>
